import Routes from 'routes';
import Locales from 'ui-component/Locales';
import ThemeCustomization from 'themes';
import ScrollToTop from "./ui-component/extended/ScrollToTop";

const App = () => {

    return (
        <ThemeCustomization>
            <Locales>
                <Routes />
                <ScrollToTop />
            </Locales>
        </ThemeCustomization>
    );
};

export default App;
