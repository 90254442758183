import React from 'react';
import {styled} from "@mui/material/styles";
import {Fab} from "@mui/material";

const CustomStyledFab = ({hoverBackgroundColor, hoverTextColor, ...props}: any) => {
    return <Fab {...props} />;
}

const StyledFab = styled(CustomStyledFab)((props: any) => ({
    ':hover': {
        background: props.hoverBackgroundColor,
        color: props.hoverTextColor
    },
}));

export default StyledFab;