import React from 'react';
import {Box, Fade, useScrollTrigger} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StyledFab from "./StyledFab";

function ScrollToTop() {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 64, right: 16, zIndex: 1 }}
            >
                <StyledFab size="small" aria-label="scroll back to top" color={"primary"} hoverBackgroundColor={theme.palette.secondary.main} hoverTextColor={theme.palette.primary.main}>
                    <KeyboardArrowUpIcon />
                </StyledFab>
            </Box>
        </Fade>
    );
}

export default ScrollToTop;